import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Items from "../../components/Items";
import styles from "./styles.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DeliveryContext } from "../../contexts/DeliveryContext";
import { ReactNode, useContext, useEffect, useState } from "react";
import { IDeliveryContext, isDeliveryMethod } from "../../types/delivery";
import useUrbanPartner from "../../services/useUrbanPartner";
import useCreateOrder from "../../services/useCreateOrder";
import { CartContext } from "../../contexts/CartContext";
import { ICartContext } from "../../types/cart";
import useSeed from "../../services/useSeed";
import { getWeekdayName } from "../../utils/datetimeUtils";
import formatPhone from "../../utils/formatPhone";
import { IProduct } from "../../types/order";
import { MessagesContext } from "../../contexts/MessagesContext";
import { IMessagesContext } from "../../types/messages";

const Checkout = () => {
  const { isLoading: isLoadingUrbanPartner, data: urbanPartner } =
    useUrbanPartner();
  const { address, deliveryMethod, setDeliveryMethod, setAddress } = useContext(
    DeliveryContext,
  ) as IDeliveryContext;
  const { emptyCart, getCart, removeItem } = useContext(
    CartContext,
  ) as ICartContext;
  const { addMessage } = useContext(MessagesContext) as IMessagesContext;
  const { slug } = useParams();
  const cart = getCart(slug);
  const {
    error,
    isError,
    isLoading: isLoadingCreateOrder,
    mutate: createOrder,
    reset,
  } = useCreateOrder({
    address: address ? address : "",
    items: Object.keys(cart).map((productId) => {
      return {
        product: parseInt(productId),
        amount: cart[parseInt(productId)].amount,
      };
    }),
    delivery_method: deliveryMethod,
    slug: slug ? slug : "",
  });
  const [open, setOpen] = useState(false);
  const [newAddress, setNewAddress] = useState("");
  const [isFormValid, setIsFormValid] = useState(deliveryMethod !== null);
  const navigate = useNavigate();
  const { data: seed, isLoading: isLoadingSeed } = useSeed();

  useEffect(() => {
    const totalItems = Object.keys(cart).length;

    if (totalItems === 0) {
      navigate(`/${slug}`);
    }
  }, [cart, navigate, slug]);

  useEffect(() => {
    if (isError) {
      let errorMessage: ReactNode = `Houve um problema ao criar seu pedido, entre em contato com a sua semeadora em ${formatPhone(seed.seeder.phone)}.`;
      const anyError = error as any;
      if (
        anyError &&
        anyError.code &&
        anyError.code[0] === "out_of_stock_products"
      ) {
        errorMessage = (
          <Box>
            <Box>
              Os seguintes produtos do seu carrinho estão fora de estoque:{" "}
              {anyError.details.products
                .map((product: IProduct) => product.base_product.name)
                .join(", ")}
            </Box>
            <Box
              sx={{ cursor: "pointer", marginTop: "0.5rem" }}
              onClick={() => {
                if (slug) {
                  anyError.details.products.forEach((product: IProduct) => {
                    removeItem(product, slug);
                  });
                  reset();
                }
              }}
            >
              Se quiser removê-los, clique aqui.
            </Box>
          </Box>
        );
      }

      addMessage({
        closeable: true,
        content: errorMessage,
        severity: "error",
      });
    }
  }, [addMessage, error, isError, removeItem, reset, seed.seeder.phone, slug]);

  if (isLoadingUrbanPartner || isLoadingSeed) {
    return <CircularProgress />;
  }

  const items = Object.keys(cart).map((productId) => cart[parseInt(productId)]);
  const subtotal = items.reduce((total, item) => {
    return total + item.amount * parseFloat(item.product.price);
  }, 0);
  const credit = urbanPartner ? parseFloat(urbanPartner.credit) : 0;
  const deliveryFee = parseFloat(seed.delivery_fee);
  const totalItems = items.length;
  const deliveryWeekday = getWeekdayName(seed.delivery_weekday);
  const hasDelivery = seed.has_delivery;
  const total = () => {
    if (hasDelivery && deliveryMethod === "delivery") {
      if (credit > subtotal + deliveryFee) {
        return 0;
      } else {
        const total = subtotal - credit + deliveryFee;
        return total.toFixed(2);
      }
    } else {
      if (credit > subtotal) {
        return 0;
      } else {
        const total = subtotal - credit;
        return total.toFixed(2);
      }
    }
  };

  const handleCreateOrder = () => {
    if (address !== null) {
      createOrder(undefined, {
        onSuccess: () => {
          navigate(
            `/${slug}/payment?total=${total()}&delivery=${deliveryMethod}`,
          );
          emptyCart();
          setDeliveryMethod(null);
          setAddress(null);
        },
      });
    }
  };

  const handleSave = () => {
    setAddress(newAddress);
    setOpen(false);
  };

  if (totalItems === 0) {
    return null;
  }

  return (
    <Box>
      <div style={{ margin: "0 auto", maxWidth: "900px", padding: "2rem" }}>
        <Typography variant="h4" component="h2">
          Modo de entrega
        </Typography>
        {isFormValid || (
          <Typography color="primary.main">
            Selecione uma opção de entrega
          </Typography>
        )}
        <FormControl>
          <RadioGroup
            onChange={(e) => {
              if (isDeliveryMethod(e.target.value)) {
                setDeliveryMethod(e.target.value);
                setIsFormValid(true);
              }
            }}
            sx={{ marginBottom: "1rem" }}
            value={deliveryMethod}
          >
            <FormControlLabel
              value="takeout"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "2rem",
                    },
                  }}
                />
              }
              label={
                <div>
                  <Typography variant="h6" component="div">
                    Retirar na semente
                  </Typography>
                  <Typography variant="body1" component="div">
                    Rua Fernando Machado, 464, Centro Histórico
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    color="text.secondary"
                  >
                    O dia para retirada da sua feira é: {deliveryWeekday}.
                  </Typography>
                </div>
              }
            />
            {hasDelivery && (
              <FormControlLabel
                value="delivery"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: "2rem",
                      },
                    }}
                  />
                }
                label={
                  <div>
                    <Typography variant="h6" component="div">
                      Quero receber em casa (+ R$ {deliveryFee.toFixed(2)})
                    </Typography>
                    <Typography variant="body1" component="div">
                      {address}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      color="text.secondary"
                    >
                      Sua entrega está programada para: {deliveryWeekday}.
                    </Typography>
                  </div>
                }
              />
            )}
          </RadioGroup>
        </FormControl>
        <Button
          onClick={() => {
            setOpen(true);
          }}
          sx={{ display: "block" }}
        >
          Trocar endereço
        </Button>
        <Typography variant="h4" component="h2">
          Resumo
        </Typography>
        <Items />
        <Typography sx={{ textAlign: "right" }} variant="h6" component="div">
          Subtotal: R$ {subtotal.toFixed(2)}
        </Typography>
        {deliveryMethod === "delivery" && (
          <Typography sx={{ textAlign: "right" }} variant="h6" component="div">
            Frete: R$ {deliveryFee.toFixed(2)}
          </Typography>
        )}
        {credit > 0 && (
          <Typography sx={{ textAlign: "right" }} variant="h6" component="div">
            Crédito: R$ {credit}
          </Typography>
        )}
        <Typography sx={{ textAlign: "right" }} variant="h5" component="div">
          Total: R$ {total()}
        </Typography>
        <Box textAlign="center">
          <Button
            disabled={
              isLoadingCreateOrder || !isFormValid || urbanPartner === null
            }
            onClick={handleCreateOrder}
            className={styles.btn}
            variant="contained"
          >
            Continuar para pagamento
          </Button>
          {urbanPartner === null && (
            <Box
              sx={{
                margin: "1rem 0",
              }}
            >
              Faça{" "}
              <Box
                sx={{
                  color: "primary.main",
                  display: "inline",
                  textDecoration: "underline",
                }}
              >
                <Link to={`/login?slug=${slug}&page=checkout`}>login</Link>
              </Box>{" "}
              para poder seguir para o pagamento
            </Box>
          )}
        </Box>
        <Box textAlign="center">
          <Link to={`/${slug}`}>
            <Button className={styles.btn}>Voltar para a loja</Button>
          </Link>
        </Box>
        <Dialog
          fullWidth
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <DialogTitle>Endereço</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              label="endereço"
              fullWidth
              variant="standard"
              value={newAddress}
              onChange={(e) => {
                setNewAddress(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancelar
            </Button>
            <Button onClick={handleSave}>Salvar</Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
};

export default Checkout;

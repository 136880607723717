import useProducts from "../../services/useProducts";
import { useContext, useEffect, useRef, useState } from "react";
import { groupBy, sortBy } from "lodash";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Category from "./Category";
import Cart from "./Cart";
import useSeed from "../../services/useSeed";
import { IProduct } from "../../types/order";
import Product from "./Product";
import {
  getEndMoment,
  getStartMoment,
  weekdayNameMap,
} from "../../utils/datetimeUtils";
import { Option } from "../../types/order";
import { isSeedOpen } from "../../utils/seed";
import { CartContext } from "../../contexts/CartContext";
import { ICartContext } from "../../types/cart";

const Store = () => {
  const { data: products, isLoading: isLoadingProducts } = useProducts();
  const { data: seed, isLoading: isLoadingSeed } = useSeed();
  const [item, setItem] = useState<null | string | Option>(null);
  const cartRef = useRef(useContext(CartContext) as ICartContext);
  const [closedSeedDialogOpen, setClosedSeedDialogOpen] = useState(false);

  useEffect(() => {
    if (seed) {
      if (!isSeedOpen(seed)) {
        cartRef.current.emptyCart();
        setClosedSeedDialogOpen(true);
      }
    }
  }, [cartRef, seed, setClosedSeedDialogOpen]);

  if (isLoadingProducts || isLoadingSeed) {
    return <CircularProgress />;
  }

  if (!products) {
    return null;
  }

  const groupedProducts = products.map((product: IProduct) => ({
    name: product.base_product.categories[0].name,
    order: product.base_product.categories[0].order,
    product,
  }));

  const sortedCategories = sortBy(groupedProducts, ["order", "name"]);
  const productsByCategories = groupBy(sortedCategories, "name");

  const categories = Object.keys(productsByCategories).map((categoryName) => ({
    name: categoryName,
    products: sortBy(
      productsByCategories[categoryName].map((item) => item.product),
      "base_product.name",
    ),
  }));

  const filtered = products.filter((product: IProduct) => {
    if (!item) {
      return true;
    }

    if (typeof item === "string") {
      const tokens = item.split(" ");
      return tokens
        .map((token) => token.toLowerCase())
        .every((token) => {
          const productName = product.base_product.name.toLowerCase();
          const producer = product.producer.name.toLowerCase();
          return productName.includes(token) || producer.includes(token);
        });
    }

    return product.id === item.value;
  });

  const onItemFilter: (_: any, newValue: null | string | Option) => void = (
    _,
    newValue,
  ) => {
    setItem(newValue);
  };

  const items: Option[] = products.map((product: IProduct) => ({
    label: `${product.base_product.name} - ${product.producer.name}`,
    value: product.id,
  }));

  const sortedItems = sortBy(items, "label");

  const {
    start_orders_weekday,
    start_orders,
    stop_orders_weekday,
    stop_orders,
  } = seed;

  const startMoment = getStartMoment(start_orders_weekday, start_orders);
  const endMoment = getEndMoment(
    startMoment,
    start_orders_weekday,
    stop_orders_weekday,
    stop_orders,
  );

  return (
    <div>
      <Dialog
        open={closedSeedDialogOpen}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        onClose={() => {
          setClosedSeedDialogOpen(false);
        }}
      >
        <DialogTitle id="dialog-title">Feira fechada</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            O horário de funcionamento da feira é de{" "}
            {weekdayNameMap[start_orders_weekday]} às{" "}
            {startMoment.local().format("HH:mm")} até{" "}
            {weekdayNameMap[stop_orders_weekday]} às{" "}
            {endMoment.local().format("HH:mm")}.
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Box sx={{ p: "2rem" }}>
        <Box sx={{ mb: 6 }}>
          <Autocomplete
            value={item}
            onChange={onItemFilter}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            disablePortal
            options={sortedItems}
            sx={{ width: "50%" }}
            renderInput={(params) => (
              <TextField {...params} label="Pesquisar" />
            )}
            freeSolo
          />
        </Box>
        <Grid container spacing={4}>
          {item !== null
            ? filtered.map((product: IProduct) => (
                <Product product={product} key={product.id} />
              ))
            : categories.map((category) => (
                <Category category={category} key={category.name} />
              ))}
        </Grid>
        <Cart />
      </Box>
    </div>
  );
};

export default Store;

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { FC, useContext } from "react";
import { CartContext } from "../../contexts/CartContext";
import { ICartContext, IProduct } from "../../types/cart";
import { useParams } from "react-router-dom";
import useSeed from "../../services/useSeed";
import { isSeedOpen } from "../../utils/seed";

interface AmountButtonProps {
  product: IProduct;
}

const AmountButton: FC<AmountButtonProps> = ({ product }) => {
  const { decreaseItem, getCart, increaseItem } = useContext(
    CartContext,
  ) as ICartContext;
  const { slug } = useParams();
  const { data: seed } = useSeed();

  const cart = getCart(slug);

  const item = cart[product.id];
  const unlimited_stock = product.unlimited_stock;
  let amount = 0;
  if (item) {
    amount = item.amount;
  }

  const handleDecrease = (product: IProduct) => {
    if (slug) {
      decreaseItem(product, slug);
    }
  };

  const handleIncrease = (product: IProduct) => {
    if (slug) {
      increaseItem(product, slug);
    }
  };

  const disabled = !isSeedOpen(seed);

  if (amount === 0 || disabled) {
    return (
      <Button
        disabled={disabled}
        onClick={() => {
          handleIncrease(product);
        }}
        variant="contained"
        sx={{ width: "100%" }}
      >
        Comprar
      </Button>
    );
  }

  return (
    <Box sx={{ flexDirection: "column", justifyContent: "center" }}>
      <TextField
        variant="outlined"
        inputProps={{ disabled: true, style: { textAlign: "center" } }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={() => {
                  handleDecrease(product);
                }}
              >
                <Remove />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  handleIncrease(product);
                }}
              >
                <Add />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={amount}
        sx={{ width: "100%" }}
      />
      {!unlimited_stock && amount === product.stock && (
        <Typography
          variant="body2"
          component="div"
          color="text.secondary"
          sx={{ mt: 1 }}
        >
          A quantidade máxima em estoque deste item foi atingida.
        </Typography>
      )}
    </Box>
  );
};

export default AmountButton;

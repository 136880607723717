import { FC, ReactNode, createContext, useCallback, useState } from "react";
import { isEqual } from "lodash";
import { IMessagesContext, Message } from "../types/messages";

export const MessagesContext = createContext<IMessagesContext | null>(null);

interface Props {
  children: ReactNode;
}

const MessagesProvider: FC<Props> = ({ children }) => {
  const [messages, setMessages] = useState<Message[]>([]);

  const addMessage = useCallback(
    (message: Message) => {
      const messageAlreadyExists = messages.some((item) =>
        isEqual(item, message),
      );
      if (!messageAlreadyExists) {
        setMessages([...messages, message]);
      }
    },
    [messages, setMessages],
  );

  const removeMessage = useCallback(
    (index: number) => {
      setMessages([...messages.slice(0, index), ...messages.slice(index + 1)]);
    },
    [messages, setMessages],
  );

  return (
    <MessagesContext.Provider
      value={{
        addMessage,
        removeMessage,
        messages,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export default MessagesProvider;

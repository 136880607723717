import { Alert, Collapse } from "@mui/material";
import { MessagesContext } from "../../contexts/MessagesContext";
import { useContext } from "react";
import { IMessagesContext } from "../../types/messages";

const Messages = () => {
  const { removeMessage, messages } = useContext(
    MessagesContext,
  ) as IMessagesContext;

  return (
    <Collapse in={messages.length > 0}>
      {messages.map((message, index) => (
        <Alert
          key={index}
          onClose={
            message.closeable
              ? () => {
                  removeMessage(index);
                }
              : undefined
          }
          severity={message.severity}
        >
          {message.content}
        </Alert>
      ))}
    </Collapse>
  );
};

export default Messages;

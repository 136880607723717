import { isNowBetweenInterval } from "./datetimeUtils";

export const isSeedOpen = (seed: any) => {
  const {
    start_orders_weekday,
    start_orders,
    stop_orders_weekday,
    stop_orders,
  } = seed;

  return isNowBetweenInterval(
    start_orders_weekday,
    start_orders,
    stop_orders_weekday,
    stop_orders,
  );
};

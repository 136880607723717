import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";

dayjs.extend(isBetween);
dayjs.extend(utc);

export const weekdayMap = {
  SUN: 0,
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6,
};

type WeekdayName =
  | "domingo"
  | "segunda-feira"
  | "terça-feira"
  | "quarta-feira"
  | "quinta-feira"
  | "sexta-feira"
  | "sábado";

interface WeekdayNameMap {
  [key: string]: WeekdayName;
}

export const weekdayNameMap: WeekdayNameMap = {
  SUN: "domingo",
  MON: "segunda-feira",
  TUE: "terça-feira",
  WED: "quarta-feira",
  THU: "quinta-feira",
  FRI: "sexta-feira",
  SAT: "sábado",
};

function isWeekdayMapKey(val: any): val is keyof typeof weekdayMap {
  return Object.keys(weekdayMap).includes(val);
}

export function getWeekdayName(key: keyof typeof weekdayNameMap): WeekdayName {
  return weekdayNameMap[key];
}

const setTime = (moment: dayjs.Dayjs, time: string) => {
  const timeRegexp = /\d\d:\d\d:\d\d/;

  if (!timeRegexp.test(time)) {
    throw Error("time must have the format \\d\\d:\\d\\d:\\d\\d");
  }

  return moment
    .hour(parseInt(time.split(":")[0]))
    .minute(parseInt(time.split(":")[1]))
    .second(parseInt(time.split(":")[2]));
};

const getWeekdayDifferenceInDays = (minuend: number, subtrahend: number) => {
  if (minuend < 0 || minuend > 6 || subtrahend < 0 || subtrahend > 6) {
    throw Error("weekdays be between 0 and 6");
  }

  return (minuend - subtrahend + 7) % 7;
};

export const getStartMoment = (startWeekday: string, startTime: string) => {
  if (!isWeekdayMapKey(startWeekday)) {
    throw Error(
      `startWeekday must be one of ${Object.keys(weekdayMap).join(", ")}`,
    );
  }

  const now = dayjs.utc();
  const startMoment = setTime(
    now.subtract(
      getWeekdayDifferenceInDays(now.day(), weekdayMap[startWeekday]),
      "day",
    ),
    startTime,
  );

  return startMoment;
};

export const getEndMoment = (
  startMoment: Dayjs,
  startWeekday: string,
  endWeekday: string,
  endTime: string,
) => {
  if (!isWeekdayMapKey(startWeekday) || !isWeekdayMapKey(endWeekday)) {
    throw Error(
      `startWeekday and endWeekday must be one of ${Object.keys(
        weekdayMap,
      ).join(", ")}`,
    );
  }

  const endMoment = setTime(
    startMoment.add(
      getWeekdayDifferenceInDays(
        weekdayMap[endWeekday],
        weekdayMap[startWeekday],
      ),
      "day",
    ),
    endTime,
  );

  return endMoment;
};

export const isNowBetweenInterval = (
  startWeekday: string,
  startTime: string,
  endWeekday: string,
  endTime: string,
) => {
  const startMoment = getStartMoment(startWeekday, startTime);
  const endMoment = getEndMoment(
    startMoment,
    startWeekday,
    endWeekday,
    endTime,
  );
  const now = dayjs.utc();

  return now.isBetween(startMoment, endMoment);
};

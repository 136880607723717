import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Drawer,
  Fab,
  IconButton,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import Items from "../../components/Items";
import { Close, Delete, ShoppingCart } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import styles from "./styles.module.css";
import { CartContext } from "../../contexts/CartContext";
import { ICartContext } from "../../types/cart";

const Cart = () => {
  const [open, setOpen] = useState(false);
  const [emptyCartModalOpen, setEmptyCartModalOpen] = useState(false);
  const { emptyCart, getCart } = useContext(CartContext) as ICartContext;
  const { slug } = useParams();

  const cart = getCart(slug);

  const items = Object.keys(cart).map((productId) => cart[parseInt(productId)]);
  const total = items.reduce((total, item) => {
    return total + item.amount * parseFloat(item.product.price);
  }, 0);

  const totalItems = items.length;

  const closeEmptyCartModal = () => {
    setEmptyCartModalOpen(false);
  };
  const openEmptyCartModal = () => {
    setEmptyCartModalOpen(true);
  };
  const confirmEmptyCart = () => {
    emptyCart();
    setEmptyCartModalOpen(false);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{
          sx: {
            boxSizing: "border-box",
            padding: "1rem",
            overflowWrap: "break-word",
            width: "22rem",
          },
        }}
      >
        <div className={styles.close_container}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </div>
        <Typography variant="h5" component="h4" gutterBottom>
          Meus itens
        </Typography>
        <Items />
        <Typography sx={{ textAlign: "right" }} variant="h6" component="div">
          Total: R$ {total.toFixed(2)}
        </Typography>
        {totalItems > 0 ? (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Link to="checkout">
              <Button variant="contained" sx={{ width: "100%" }}>
                Tudo certo!
              </Button>
            </Link>
            <Button
              color="error"
              onClick={openEmptyCartModal}
              sx={{ width: "100%" }}
            >
              <Delete /> Esvaziar carrinho
            </Button>
          </Box>
        ) : null}
      </Drawer>
      <Dialog
        open={emptyCartModalOpen}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          Você realmente deseja esvaziar o carrinho?
        </DialogTitle>
        <DialogActions>
          <Button onClick={closeEmptyCartModal}>Não</Button>
          <Button color="error" onClick={confirmEmptyCart}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <Fab
        aria-label="cart"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
        sx={{
          position: "fixed",
          right: "2rem",
          top: "5rem",
        }}
      >
        <ShoppingCart />
      </Fab>
    </>
  );
};

export default Cart;

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import CartProvider from "./contexts/CartContext";
import AuthProvider from "./contexts/AuthContext";
import DeliveryProvider from "./contexts/DeliveryContext";
import Store from "./pages/Store";
import Login from "./pages/Login";
import Checkout from "./pages/Checkout";
import Payment from "./pages/Payment";
import Seeds from "./pages/Seeds";
import Root from "./components/Root";
import Signup from "./pages/Signup";
import EmailConfirmation from "./pages/EmailConfirmation";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import MessagesProvider from "./contexts/MessagesContext";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Seeds />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "signup",
    element: <Signup />,
  },
  {
    path: "email-confirmation",
    element: <EmailConfirmation />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/:slug",
    element: <Root />,
    children: [
      {
        element: <Store />,
        index: true,
      },
      {
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "payment",
        element: <Payment />,
      },
    ],
  },
]);

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#447737",
    },
    secondary: {
      main: "#9fd236",
      dark: "#82a221",
      light: "#d1ea76",
    },
    background: {
      default: "#f5f2e5",
      paper: "#ffffff",
    },
    text: {
      secondary: "#2f5326",
      disabled: "#2f5326",
    },
    success: {
      main: "#4ddc8c",
    },
    error: {
      main: "#f75e5e",
    },
    warning: {
      main: "#f79f57",
    },
    info: {
      main: "#6ebee8",
    },
    divider: "rgba(68,119,55,0.24)",
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <MessagesProvider>
          <CartProvider>
            <DeliveryProvider>
              <ThemeProvider theme={theme}>
                <RouterProvider router={router} />
              </ThemeProvider>
            </DeliveryProvider>
          </CartProvider>
        </MessagesProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
